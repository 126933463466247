import appService from '@/modules/app/services/appService';
import embeddedAppStore from '@/modules/app/store/embeddedAppStore';

const state = {
  billingApp: undefined,
  availableMarketApps: [],
  externalApplications: [],
};

/**
 * Only these apps will use the pov implementation of embedded apps
 * Note: You also have to update embedded-controller.js.coffee, so the app is ignored there
 */
const POV_EMBEDDED_APPS = ['pickmycall', 'partnerAppQa', 'manorstestapp', 'apptor', 'smartslotstest', 'mypa', 'mypatest', 'pickmycallfree', 'smartslots'];

// TODO: this data should be moved to the manifest once it's implemented in the backend
const specificAppData = {
  // To remove the header for a specific app
  // [app_code_name]: {
  //   show_header: false,
  // },
};

const mutations = {
  // TODO: is there a duplication of data here? can we normalize it so that a billing app is just a getter?
  setBillingApp(state, app) {
    state.billingApp = app;
  },
  setAvailableMarketApps(state, apps) {
    state.availableMarketApps = [...Object.values(apps).map((item) => {
      if (POV_EMBEDDED_APPS.includes(item.app_code_name)) {
        return {
          ...item, migrated: true, show_header: true, ...specificAppData[item.app_code_name],
        };
      }
      return item;
    })];
  },
  setExternalApplications(state, apps) {
    state.externalApplications = [...Object.values(apps).map((item) => {
      if (POV_EMBEDDED_APPS.includes(item.app_code_name)) {
        return {
          ...item, migrated: true, show_header: true, ...specificAppData[item.app_code_name],
        };
      }
      return item;
    })];
  },
};

const actions = {
  async loadBillingAppData({ commit, state }, businessUid) {
    if (!state.billingApp && !state.billingApp?.uid) {
      const result = await appService.getAppsByType({ type: 'billing_app', businessUid });
      if (result.code !== 'error') {
        commit('setBillingApp', result.apps[0]);
      }
    }
  },
  async getAvailableMarketApps({ commit, state }, force = false) {
    if (!state.availableMarketApps.length || force) {
      try {
        const result = await appService.getAvailableMarketApps();
        commit('setAvailableMarketApps', result.apps);
      } catch (error) {
        console.error('Failed to call getAvailableMarketApps', error);
      }
    }
  },
  async getExternalApplications({ commit, state }, force = false) {
    if (!state.externalApplications.length || force) {
      try {
        const result = await appService.getExternalApps();
        commit('setExternalApplications', result.apps);
      } catch (error) {
        console.error('Failed to call getExternalApps', error);
      }
    }
  },
};

const getters = {
  billingApp: (state) => state.billingApp,
  availableMarketApps: (state) => state.availableMarketApps,
  getMarketAppById: (state) => (id) => state.availableMarketApps.find((app) => app.app_code_name === id),
  externalApplications: (state) => state.externalApplications,
  getExternalAppById: (state) => (id) => state.externalApplications.find((app) => app.app_code_name === id),
};

const modules = {
  EmbeddedAppStore: embeddedAppStore,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules,
};
