<template>
  <div data-testid="BookExample">
    <h3>Books Example</h3>
    <VcButton @click="fireClick" flavor="primary">Get books</VcButton>
    <div v-if="books.length">
      list of books:
      <ol>
        <li v-for="book in books" :key="book">{{ book }}</li>
      </ol>
    </div>
  </div>
</template>

<script>
import getBooksService from './BooksService';

export default {
  props: {
    msg: String,
  },
  data() {
    return {
      books: [],
    };
  },
  created() {
    setTimeout(() => {
      this.$emit('ready');
    }, 3000);
  },
  methods: {
    async getBooks() {
      try {
        const booksResponse = await getBooksService();
        const randomBooks = Math.floor(Math.random() * 100 - 10);
        this.books = Array.from(new Set(booksResponse.docs.map((entity) => entity.title))).splice(randomBooks, 5);
      } catch (e) {
        console.log(e);
      }
    },
    fireClick() {
      console.log('clicked');
      this.getBooks();
    },
  },
};
</script>

<style scoped>
h3 {
  color: red;
}

ol {
  padding: 0 36px;
}
</style>
