import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import LayoutConstants from '@/constansts/layoutConstants';
import mainMenuService from '@/modules/layout/mainMenu/services/mainMenuService';
import menuObject from '@/modules/layout/mainMenu/menuItems.json';
import MenuItemsUtils from '@/modules/layout/mainMenu/utils/menuItemsUtils';
import loggerService from '@/modules/common/services/loggerService';

const state = {
  applicationsMenuItems: {},
  applicationMenuItemsLoaded: false,
  externalApplications: {},
  externalApplicationsLoaded: false,
  menuItems: [],
  menuItemsLoaded: false,
  shouldShowMenu: false,
  isMenuTranslationLoading: true,
  checkListProgress: {
    show: false,
    currentStep: null,
    totalSteps: null,
  },
};

const mutations = {
  setApplicationsMenuItems(state, applicationsMenuItems) {
    state.applicationsMenuItems = applicationsMenuItems;
    state.applicationMenuItemsLoaded = true;
  },
  setExternalApplications(state, externalApplications) {
    state.externalApplications = externalApplications;
    state.externalApplicationsLoaded = true;
  },
  setMenuItems(state, menuItems) {
    state.menuItems = menuItems;
    state.menuItemsLoaded = true;
  },
  setShouldShowMenu(state, value) {
    state.shouldShowMenu = value;
  },
  setIsMenuTranslationLoading(state, value) {
    state.isMenuTranslationLoading = value;
  },
  setChecklistProgressStatus(state, value) {
    state.checkListProgress = value;
  },
};

const actions = {
  async fetchApplicationsMenuItems({ commit, state }) {
    if (state.applicationMenuItemsLoaded && state.externalApplicationsLoaded) return;
    try {
      const allExternalApplications = await mainMenuService.getExternalApplications();
      const applicationsInMenu = allExternalApplications.filter((app) => app.menu_items?.subitems?.length > 0);
      const applicationsMenuItems = MenuItemsUtils.serializeApplicationsMenuItems(applicationsInMenu);
      commit('setApplicationsMenuItems', applicationsMenuItems);
      commit('setExternalApplications', allExternalApplications);
    } catch (e) {
      console.error('error', e);
    }
  },
  updateShouldShowMenu({ commit }, value) {
    commit('setShouldShowMenu', value);
  },
  updateIsMenuTranslationLoading({ commit }, value) {
    commit('setIsMenuTranslationLoading', value);
  },
  openSearchAction({ commit }) {
    const route = 'app.search';
    const obj = { route };
    outgoingPostMessageUtils.post(LayoutConstants.NAVIGATE_TO, obj);
    commit('setShouldShowMenu', false);
  },
  dispatchMenuItemAction({ commit, rootGetters, dispatch }, actionItem) {
    const isAngularPage = rootGetters['AngularStore/isAngularDisplayed'];
    const isAppMarket = actionItem.action === 'openAppMarket';
    if (!isAngularPage && isAppMarket) {
      dispatch('ModalStore/setForceModalMode', true, { root: true });
      MenuItemsUtils.fireAction(actionItem, true);
    } else {
      MenuItemsUtils.fireAction(actionItem);
    }
    commit('setShouldShowMenu', false);
  },
  dispatchPovMenuItemAction({ rootGetters }, povActionItem) {
    const directorySettings = rootGetters['BusinessStore/directorySettings'];
    switch (povActionItem) {
      case 'backToBackofficeDashboard':
        if (directorySettings?.external_backoffice_url) {
          window.open(directorySettings.external_backoffice_url, '_blank');
        }
        break;
      default:
        break;
    }
  },
  setChecklistProgress({ commit }, progressData) {
    if (progressData && typeof progressData?.currentStep === 'number' && typeof progressData?.totalSteps === 'number') {
      commit('setChecklistProgressStatus', { ...progressData, show: MenuItemsUtils.shouldShowProgressBar(progressData) });
    }
  },
  async aggregateMenuItemsForStaff({
    state, rootGetters, commit, dispatch,
  }) {
    await dispatch('fetchApplicationsMenuItems');
    const filteredMenuItems = [];
    menuObject.menuItems.forEach((categoriesBulk) => {
      try {
        const filteredArray = categoriesBulk.reduce(
          MenuItemsUtils.getReduceMenuItemsByCategoryFunction(state, rootGetters),
          [],
        );
        filteredMenuItems.push(filteredArray);
      } catch (error) {
        setTimeout(() => {
          loggerService.error('MAIN-MENU-AGGREGATE-MENU-ITEMS', { error, categoriesBulk });
        }, LayoutConstants.WAIT_TIME_FOR_ANGULAR_TO_LOAD);
      }
    });
    commit('setMenuItems', filteredMenuItems);
  },
};

const getters = {
  getApplicationsMenuItems: (state) => state.applicationsMenuItems,
  externalApplications: (state) => state.externalApplications,
  shouldShowMenu: (state) => state.shouldShowMenu,
  isMenuTranslationLoading: (state) => state.isMenuTranslationLoading,
  menuItems: (state) => state.menuItems,
  checklistProgress: (state) => state.checkListProgress,
  allMenuItemsLoaded: (state) => state.applicationMenuItemsLoaded && state.menuItemsLoaded && state.externalApplicationsLoaded,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
