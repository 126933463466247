<template>
  <iframe class="app-iframe"
          :id="`${appId}-app-iframe`"
          :src="appUrl"
          :data-qa="`${appId}-app-iframe`"
          :title="`${appId}-app-iframe`"></iframe>
</template>
<script>
import communicationSdk, { startCommunicationWithSdk } from '@/modules/common/util/communicationSdk';

export default {
  name: 'EmbeddedAppDelegatorIframe',
  props: {
    appId: {
      type: String,
      required: true,
    },
    appUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      appChannel: null,
    };
  },
  created() {
    this.settingChannel();
  },
  computed: {
    appDomain() {
      return new URL(this.appUrl).origin;
    },
    iframeId() {
      return `${this.appId}-app-iframe`;
    },
  },
  methods: {
    settingChannel() {
      this.appChannel = startCommunicationWithSdk(this.appDomain, this.iframeId);
      this.appChannel.onmessage = this.handleMessage;
    },
    async handleMessage({ actionName, data }) {
      if (actionName === 'SetState' && data === 'Ready') {
        this.ready();
        return;
      }
      const actionResponse = await communicationSdk(actionName, data);
      this.appChannel.postMessage({ actionName, data: actionResponse });
    },
    ready() {
      this.$emit('ready');
    },
  },
};
</script>
<style scoped lang="scss">
.app-iframe {
  pointer-events: all;
  width: 100%;
  height: 100%;
  border: unset;
  display: block;
}
</style>
