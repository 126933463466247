<template>
  <div>
    <VcDropdown v-bind="$attrs" max-height="500px"
                :value="dropdownState"
                z-index="205"
                @input="this.toggleDropdownMenuOpen"
    >
      <template #activator="{ on, attrs }">
        <slot name="activator" v-bind="attrs" v-on="on" />
      </template>
      <template #header>
        <VcLayout v-if="screen === SCREENS.SHOW_ALL_OPTIONS || screen === SCREENS.CUSTOM_GENERATE">
          <VcMenuItem
            :label="subMenuHeaderTitle"
            :icon="rtl ? 'icon-Chevron-right': 'icon-Chevron-left'"
            size="sm"
            @click="onClickBackMainMenu"
          ></VcMenuItem>
        </VcLayout>
      </template>
      <template #default>
        <VcLayout class="dropdown-content-wrapper" column>
          <VcLayout justify-center align-center>
            <VcLoader class="loader" v-if="!promptGroups.length && !error.text" />
          </VcLayout>

          <VcLayout v-if="screen === SCREENS.MAIN_MENU">
            <VcLayout column>
              <VcAlert
                class="alert-error"
                v-if="error.text || error.header"
                :type="error.type"
                :header-text="error.header"
                :body-text="error.text"
                :is-inline-header=true
              />

              <VcAlert
                class="alert-error"
                v-if="missingRequiredVariables.length > 0"
                type="attention"
                :body-text="$t('ai_content_generator.errors.missing_required_variables.text', {
                  comma_separated_fields : missingRequiredVariables.join(', '),
                  })"
                :is-inline-header=true
              />

              <VcGroupedItems :item-groups="menuItems" show-dividers @change="onClickPromptItem">
                <template #item="{ item }">
                  <MenuItem :item="item" data-qa="prompt-menu-item" />
                </template>
              </VcGroupedItems>
            </VcLayout>
          </VcLayout>

          <VcLayout v-else-if="screen === SCREENS.SHOW_ALL_OPTIONS">
            <VcGroupedItems :item-groups="selectedPromptGroup.moreItems"
                            @change="onClickPromptItem">
              <template #item="{ item }">
                <MenuItem :item="item" />
              </template>
            </VcGroupedItems>
          </VcLayout>

          <VcLayout v-else-if="screen === SCREENS.CUSTOM_GENERATE">
            <CustomInstructions @generate="onCustomGenerate"></CustomInstructions>
          </VcLayout>
          <VcLayout v-if="disclaimerText" column>
            <VcDivider class="ma-0" />
            <p class="my-3 mx-4 disclaimer">{{ disclaimerText }}</p>
          </VcLayout>
        </VcLayout>
      </template>
    </VcDropdown>
  </div>
</template>

<script>
import MenuItem from '@/modules/aiContentGenerator/components/MenuItem.vue';
import CustomInstructions from '@/modules/aiContentGenerator/components/CustomInstructions.vue';
import { mapActions, mapGetters } from 'vuex';

const SHOW_MORE_OPTIONS_TRESHOLD = 4;
const SHOW_MORE_OPTIONS_NUMBER_TO_SHOW = 3;
const ITEM_SHOW_ALL_OPTIONS_SUFFIX = '-show-all-options';

export const SCREENS = {
  MAIN_MENU: 'SCREEN_MAIN_MENU',
  CUSTOM_GENERATE: 'SCREEN_CUSTOM_GENERATE',
  SHOW_ALL_OPTIONS: 'SCREEN_SHOW_ALL_OPTIONS',
};

function isCustomInstructionPrompt(prompt) {
  return prompt.metadata?.configuration_key === 'rewrite_with_custom_instructions'
    || prompt.metadata?.configuration_key === 'generate_with_custom_instructions';
}

export default {
  name: 'AiGenerator',
  components: {
    MenuItem,
    CustomInstructions,
  },
  props: {
    agentName: {
      type: String,
      required: true,
    },
    modelName: {
      type: String,
      required: false,
      validator: (value) => ['gpt-4'].includes(value),
    },
    feature: {
      type: String,
      required: true,
    },
    variables: {
      type: Object,
      default: () => ({}),
      validator: (props) => Object.values(props).every((v) => typeof v === 'object' && 'value' in v && 'title' in v),
    },
    targetTextValue: {
      type: String,
      default: '',
    },
    forceChatUid: {
      type: String,
      default: null,
    },
    dropdownState: {
      type: Boolean,
      default: false,
    },
    disclaimerText: {
      type: String,
      default: '',
    },
    analyticsParameters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      SCREENS,
      screen: SCREENS.MAIN_MENU,
      selectedPromptGroup: null,
      subMenuHeaderTitle: null,
      selectedPromptItem: null,
    };
  },
  created() {
    this.addI18nModule('ai-content-generator-pov');
    this.SCREENS = SCREENS;
  },
  computed: {
    ...mapGetters('AiGeneratorStore', {
      fetchPrompts: 'promptValue',
      fetchText: 'textValue',
      fetchError: 'errorValue',
      fetchIsStreaming: 'isStreaming',
      fetchChatUid: 'chatUid',
    }),
    ...mapGetters('BusinessStore', ['locale']),
    promptGroups() {
      return this.fetchPrompts(this.feature) || [];
    },
    error() {
      const err = this.fetchError(this.feature);
      if (err) {
        /* eslint-disable localization/no-logic-in-translations */
        return {
          header: this.$t(err.header),
          text: this.$t(err.text),
          type: err.type,
        };
        /* eslint-enable localization/no-logic-in-translations */
      }
      return {
        header: '',
        text: '',
      };
    },
    streamText() {
      return this.fetchText(this.feature);
    },
    isStreaming() {
      return this.fetchIsStreaming(this.feature);
    },
    hasActiveChat() {
      return Boolean(this.fetchChatUid(this.feature));
    },
    rtl() {
      return this.$vuetify.rtl;
    },
    menuItems() {
      const menuExpandIcon = (this.$vuetify.rtl ? 'icon-Chevron-left' : 'icon-Chevron-right');
      const hasMissingRequiredVariables = this.missingRequiredVariables.length > 0;
      const items = [];
      items.push(...this.promptGroups.map((group) => ({
        id: group.uid, // Design system uses id's for the items but V3 standards use uid
        ...group,
        label: group.title?.toUpperCase() || ' ', // Design system and feature design mismatch fix
        items: group.prompts
          .map((prompt) => ({
            id: prompt.uid,
            prompt,
            icon: prompt.icon,
            rightIcon: isCustomInstructionPrompt(prompt) ? menuExpandIcon : null,
            disabled: hasMissingRequiredVariables,
            handler: () => this.onClickPrompt(prompt),
          })).sort((promptItemA, promptItemB) => promptItemA.prompt.order - promptItemB.prompt.order), // Sorting step based on prompt.order
      })).sort((groupA, groupB) => groupA.order - groupB.order));

      // Add "show all options" item for each group
      for (let i = 0; i < items.length; i++) {
        const promptGroup = items[i];
        if (promptGroup.items.length > SHOW_MORE_OPTIONS_TRESHOLD) {
          promptGroup.moreItems = [{
            id: `${promptGroup.uid}-more`,
            items: promptGroup.items.slice(SHOW_MORE_OPTIONS_NUMBER_TO_SHOW),
          }];
          promptGroup.items = promptGroup.items.slice(0, SHOW_MORE_OPTIONS_NUMBER_TO_SHOW);
          promptGroup.items.push({
            id: `${promptGroup.uid}${ITEM_SHOW_ALL_OPTIONS_SUFFIX}`,
            prompt: {
              title: this.$t('ai_content_generator.show_all.menu_item_title'),
            },
            icon: null,
            disabled: hasMissingRequiredVariables,
            rightIcon: menuExpandIcon,
            handler: () => this.onClickShowAllOptions(promptGroup),
          });
        }
      }

      // set visibility and disable state for each item depending on the targetTextValue
      const hasTextContent = !!this.targetTextValue;
      for (let i = 0; i < items.length; i++) {
        const group = items[i];
        if (group.metadata?.configuration_key === 'generate') {
          if (hasTextContent) {
            group.items = group.items.filter((item) => item.prompt.metadata?.configuration_key === 'rewrite' || item.prompt.metadata?.configuration_key === 'rewrite_with_custom_instructions');
          } else {
            group.items = group.items.filter((item) => item.prompt.metadata?.configuration_key === 'generate' || item.prompt.metadata?.configuration_key === 'generate_with_custom_instructions');
          }
        } else {
          for (let j = 0; j < group.items.length; j++) {
            const promptItem = group.items[j];
            promptItem.disabled = hasMissingRequiredVariables
              || !hasTextContent
              || this.targetTextValue.length < 2;
          }
        }
      }

      return items;
    },
    missingRequiredVariables() {
      const missingVars = new Set();
      const ignoreVariables = ['populated_text', 'custom_instructions'];
      this.promptGroups.forEach((group) => {
        group.prompts.forEach((prompt) => {
          prompt.requiredVariables.forEach((variable) => {
            if (ignoreVariables.includes(variable)) {
              return;
            }
            if (!this.variables[variable]?.value) {
              missingVars.add(this.variables[variable]?.title);
            }
          });
        });
      });

      return Array.from(missingVars);
    },
  },
  methods: {
    ...mapActions('AiGeneratorStore', ['sendMessage', 'createChat', 'getPrompts']),
    toggleDropdownMenuOpen(state) {
      this.$emit('dropdownStateChange', typeof state === 'boolean' ? state : !this.dropdownState);
    },
    onClickOpenCustomGenerate(prompt) {
      this.screen = SCREENS.CUSTOM_GENERATE;
      this.subMenuHeaderTitle = prompt.title;
      this.clickedCustomGeneratePrompt = prompt;
    },
    onCustomGenerate(userInstructions) {
      this.toggleDropdownMenuOpen(false);
      this.sendPrompt(this.clickedCustomGeneratePrompt, userInstructions);
    },
    onClickPrompt(prompt) {
      if (isCustomInstructionPrompt(prompt)) {
        this.onClickOpenCustomGenerate(prompt);
      } else {
        this.toggleDropdownMenuOpen(false);
        this.sendPrompt(prompt);
      }
    },
    onClickShowAllOptions(group) {
      this.selectedPromptGroup = group;
      this.subMenuHeaderTitle = this.$t('ai_content_generator.show_all.back_to_menu');
      this.screen = SCREENS.SHOW_ALL_OPTIONS;
    },
    onClickBackMainMenu() {
      this.screen = SCREENS.MAIN_MENU;
      this.selectedPromptGroup = null;
    },
    onClickPromptItem(itemId) {
      const item = this.findItemById(itemId);
      if (!item) {
        return;
      }
      this.$track('ai-generator-prompt-clicked', {
        ...this.analyticsParameters,
        feature: this.feature,
        title: item.prompt.title,
      });
      this.$emit('promptClicked', item.prompt);
      this.selectedPromptItem = item;
      item.handler();
    },
    findItemById(itemId) {
      for (let i = 0; i < this.menuItems.length; i++) {
        const group = this.menuItems[i];
        const foundItem = group.items.find((item) => item.id === itemId);
        if (foundItem) return foundItem;

        const foundMoreItem = group.moreItems?.[0]?.items.find((item) => item.id === itemId);
        if (foundMoreItem) return foundMoreItem;
      }
      return null;
    },
    async startStream(item) {
      this.$emit('startStream', item);
    },
    async onStream(value) {
      this.$emit('onStream', value);
    },
    async endStream(data) {
      this.$emit('endStream', data || { data: '' });
    },
    async sendPrompt(item, customInstructions = null) {
      // case chat creation has failed, stop the operation
      if (!await this.ensureActiveChat()) return;

      const variables = this.prepareVariables(item, customInstructions);
      await this.sendMessage({
        feature: this.feature,
        content: {
          promptUid: item.uid,
          variables,
        },
      });
    },
    prepareVariables(item, customInstructions) {
      // for each variable extract the value property
      const requiredVariableValues = {};
      item.requiredVariables.forEach((variable) => {
        if (this.variables[variable]) {
          requiredVariableValues[variable] = this.variables[variable].value;
        }
      });
      item.optionalVariables.forEach((variable) => {
        if (this.variables[variable]) {
          requiredVariableValues[variable] = this.variables[variable].value;
        }
      });
      return {
        populated_text: this.targetTextValue,
        ...requiredVariableValues,
        custom_instructions: customInstructions,
      };
    },
    async ensureActiveChat() {
      if (!this.hasActiveChat) {
        await this.createChat({
          agentName: this.agentName,
          feature: this.feature,
          windowSize: 0,
          modelName: this.modelName,
        });
      }
      return this.hasActiveChat;
    },
  },
  watch: {
    streamText(newVal) {
      if (newVal) {
        this.onStream(newVal);
      }
    },
    isStreaming(newVal) {
      if (newVal === true) {
        this.startStream(this.selectedPromptItem);
      } else if (newVal === false) {
        this.endStream(this.streamText);
      }
    },
    async dropdownState(newVal) {
      if (newVal) {
        // ensure prompts are fetched so missing values are calculated
        await this.getPrompts({ feature: this.feature, locale: this.locale });

        this.$track('ai-generator-button-clicked', {
          ...this.analyticsParameters,
          feature: this.feature,
          isInvalid: this.missingRequiredVariables?.length > 0,
        });

        this.$emit('onDropdownOpen', {
          feature: this.feature,
          missingRequiredVariables: this.missingRequiredVariables,
        });
      }
    },
  },
};

</script>

<style scoped lang="scss">

.dropdown-content-wrapper {
  min-height: 64px;
  width: 300px;

  .loader {
    margin-top: var(--size-value3);
    margin-right: var(--size-value3);
    margin-left: var(--size-value3);
  }
}

::v-deep .v-divider {
  margin: 0 16px;
}

::v-deep .grouped-items__group__header.mb-2 {
  font-size: 10px !important;
  padding-top: 4px !important;
  padding-left: 16px !important;
  padding-right: 12px !important;
  margin-bottom: 0 !important;
}

.alert-error {
  margin: var(--size-value2);
}
.disclaimer {
  color: var(--gray-darken-4);
  font-size: var(--font-size-xx-small);
  font-weight: var(--font-weight-medium);
}

</style>
