<template>
  <VcLayout column class="widget-wrapper">
    <div class="widget-title ma-5 mb-0">{{ matterNamePluralCapitalized }}</div>
    <template v-if="isLoading">
      <VcSkeleton
          v-for="n in MAX_CLIENTS" :key="n"
          class="px-5"
          data-qa="VcSkeleton"
          type="list-item-avatar-two-line"
      />
    </template>
    <template v-else>
      <div class="views-select mt-4 mx-5">
        <VcSelectField
            :items="clientsViews"
            :label="selectLabel"
            :pre-selection="currentView"
            @selection-changed="onViewSelect"
            data-qa="VcSelectField"
        />
      </div>
      <div v-if="clients.length" class="mt-4 mx-2">
        <div v-for="client in clients"
             :key="client.matter_uid">
          <VcClientItem
              data-qa="VcClientItem"
              :matter-name="client.displayName"
              :contact-name="client.contact_full_name"
              :subtitle="lastInteraction(client)"
              :client-avatar="{
            imagePath: client.photo_path || '',
            name: client.matter_name,
            colorId: client.color_id,
            size: 'x-sm',
          }"
              show-actions-on-hover
              class="client-item pl-4"
              @click="goToClient(client)"
              @onSendMessageClicked="sendMessage(client)"
          />
        </div>
      </div>
      <VcLayout v-else align-center>
        <VcEmptyState
            :title="emptyStateTitle"
            subtitle=""
            size="md"
            :image="require('@/assets/images/crm/client_not_found.svg')"
            :button="`${$t('widget.clients_widget.new')} ${matterNameSingular}`"
            outlined-button
            data-qa="VcEmptyState"
            @click="onEmptyClick"
        />
      </VcLayout>
    </template>
  </VcLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import viewService from '@/modules/crm/services/viewService';
import BusinessDateFormats from '@/modules/common/filters/BusinessDateFormats';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';

const MAX_CLIENTS = 7;
export default {
  name: 'ClientsWidget',
  data() {
    return {
      fetchClientsData: {},
      isLoading: false,
      currentView: null,
      MAX_CLIENTS,
    };
  },
  computed: {
    ...mapGetters('BusinessStore', ['matterTermTranslation']),
    ...mapGetters('ClientStore', ['clientResults']),
    ...mapGetters('ViewStore', ['allViewsList']),
    matterNameSingular() {
      // eslint-disable-next-line localization/no-logic-in-translations
      return this.$t(this.matterTermTranslation?.lowercase_singular);
    },
    matterNamePluralCapitalized() {
      // eslint-disable-next-line localization/no-logic-in-translations
      return this.$t(this.matterTermTranslation?.capitalized_plural);
    },
    emptyStateTitle() {
      // eslint-disable-next-line localization/no-logic-in-translations
      const matterNamePlural = this.$t(this.matterTermTranslation?.lowercase_plural);
      return this.$t('client.crm_page.crm_table.empty_state.title', {
        matter_term_plural: matterNamePlural,
      });
    },
    clientsViews() {
      const viewsList = [...this.allViewsList];
      return viewsList
        .sort((a, b) => {
          if (a.order === null && b.order !== null) return 1;
          if (b.order === null && a.order !== null) return -1;
          return a.order - b.order;
        })
        .map((view) => ({ ...view, text: view.name, value: view.uid }));
    },
    clients() {
      const clients = this.clientResults(this.currentView?.uid);
      if (!clients) return [];
      return clients.map((client) => ({
        ...client,
        displayName: this.getDisplayMatterName(client.matter_name, client.client_type === 'demo_client'),
      }));
    },
    hasShowFiltersFF() {
      return this.$hasFeature('crm_show_filters');
    },
    selectLabel() {
      return `${this.matterNamePluralCapitalized} ${this.$t('widget.clients_widget.clients_view')}`;
    },
  },
  async created() {
    this.isLoading = true;
    this.addI18nModule('client-pov');
    this.addI18nModule('widgets-pov');
    await this.init();
    this.isLoading = false;
    this.$emit('ready');
  },
  methods: {
    ...mapActions('ClientStore', ['resetClients', 'fetchClients']),
    ...mapActions('ViewStore', ['fetchViews']),
    ...mapActions('ModalStore', ['openModal']),
    async init() {
      this.resetClients();
      await this.fetchViews();
      // eslint-disable-next-line prefer-destructuring
      this.currentView = this.clientsViews[0];
      await this.onFetchClients({ setClients: true }, this.currentView);
      this.loading = false;
    },
    async onViewSelect(view) {
      this.currentView = view;
      await this.onFetchClients({ setClients: true }, view);
    },
    async onFetchClients(data, view) {
      const filter = this.hasShowFiltersFF
        ? viewService.getSearchFiltersObjectFromNewDecorationFiltersObject(view.filtersObject)
        : view.filtersObject;
      const payload = { ...data, viewUid: view.uid };
      await this.fetchClients({
        ...payload, page: 1, perPage: MAX_CLIENTS, filter,
      });
      this.fetchClientsData[view.uid] = { ...payload, setClients: false };
    },
    goToClient(client) {
      outgoingPostMessageUtils.post('navigate-to', {
        route: 'app.client',
        data: { uid: client.contact_uid, matter_uid: client.matter_uid },
      });
    },
    sendMessage(client) {
      const mappedClient = {
        matter_uid: client.matter_uid,
        full_name: client.contact_full_name,
        matter_name: client.matter_name,
        photo_path: client.photo_path,
        color_id: client.color_id,
        email: client.contact_email,
      };
      this.openModal({
        name: 'NewMessage',
        functionName: 'open',
        payload: [null, mappedClient],
      });
    },
    lastInteraction(client) {
      if (!client.last_interaction) return '';
      const dayMonthText = BusinessDateFormats.dateNoCurrentYear(client.last_interaction);
      // eslint-disable-next-line localization/no-logic-in-translations
      return `${this.$t('widget.clients_widget.last_activity')}: ${dayMonthText}`;
    },
    onEmptyClick() {
      this.openModal({
        name: 'NewClient',
        identifier: 'NewMatter',
        payload: [null],
      });
    },
    getDisplayMatterName(matterName, isDemoClient) {
      return isDemoClient ? this.$t('client.crm_page.demo_client', { matterName }) : matterName;
    },
  },
};
</script>

<style scoped lang="scss">
.widget-wrapper {
  line-height: normal;
  text-align: start;
  width: 100%;
}
.widget-title {
  font-size: var(--font-size-medium2);
  font-weight: var(--font-weight-large2);
}
.clients-list {
  text-align: start;
}
.client-item {
  cursor: pointer;
}
</style>
