const routes = [
  {
    path: '/settings/payments',
    component: () => import(/* webpackChunkName: "PaymentSettings" */ './views/PaymentSettingsPage.vue'),
    name: 'payment-settings',
    props: (route) => ({
      ...route.query, featureFlag: 'payment_settings_pov', angularRoute: 'app.payments_settings_page',
    }),
    meta: { i18n: 'payment-settings-pov' },
  },
];

export default routes;
