import ScriptInjectionUtils from '@/modules/externalScripts/utils/scriptInjectionUtils';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

// Save datadogRum globally
window.datadogRum = datadogRum;
window.datadogLogs = datadogLogs;

const clientToken = 'pube9a8020d41d2a002725b152f2269943c';
const trackSessionAcrossSubdomains = true;
const usePartitionedCrossSiteSessionCookie = true;

const initDatadogRum = (env, staffUid, staffRole) => {
  const fullStoryScriptId = 'pov_datadog_rum';
  const script = `datadogRum.init({
    applicationId: '4ca47f08-1407-4a21-a404-48d9d2ad8e4d',
    clientToken: '${clientToken}',
    site: 'datadoghq.com',
    service: 'frontage',
    env: '${env}',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    usePartitionedCrossSiteSessionCookie: '${usePartitionedCrossSiteSessionCookie}',
    trackResources: true,
    allowedTracingUrls: [ /\\/api2/i ],
    trackLongTasks: true,
    trackSessionAcrossSubdomains: '${trackSessionAcrossSubdomains}',
    defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.setUser({
      id: '${staffUid}',
      role: '${staffRole}',
    });
  `;
  ScriptInjectionUtils.injectScriptToMainDom(script, fullStoryScriptId);
};

const initDatadogBrowserLogs = (env) => {
  datadogLogs.init({
    clientToken,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error'],
    sessionSampleRate: 100,
    trackSessionAcrossSubdomains,
    usePartitionedCrossSiteSessionCookie,
    env,
  });
};

export default {
  initDatadogRum,
  initDatadogBrowserLogs,
};
