<template>
  <VcLayout column class="schedule-item" @click="$emit('click', $event)">
    <div class="title-text px-1 text-center">
      {{ date }}
    </div>
    <VcLayout column justify-center class="pa-3" :justify-space-between="clients.length">
      <div class="text-center px-3" :class="meetingNum ? 'content-text' : 'empty-text'">
        {{ meetingNum || $t('widget.scheduling_widget.no_bookings') }}
      </div>
      <div v-if="mappedClients.length">
        <VcAvatarStack :avatars="mappedClients" size="x-sm" />
      </div>
    </VcLayout>
  </VcLayout>
</template>

<script>
export default {
  name: 'ScheduleItem',
  props: {
    date: { type: String, required: true },
    meetingNum: { type: Number },
    clients: { type: Array, default: () => [] },
  },
  computed: {
    mappedClients() {
      return this.clients.map((client) => ({
        ...client,
        colorId: client.color_id,
        path: client.photo_path,
        name: client.full_name,
      }));
    },
  },
};
</script>

<style scoped lang="scss">
.schedule-item {
  cursor: pointer;
  border-radius: var(--size-value1);
  border: 1px solid var(--gray-lighten-1);
}
.title-text {
  height: 37px;
  background: var(--gray-lighten-4);
  font-size: var(--font-size-x-small);
  font-weight: var(--font-weight-large);
  color: var(--gray-darken-5);
  border-top-left-radius: var(--size-value1);
  border-top-right-radius: var(--size-value1);
  align-content: center;
}
.content-text {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-large);
}
.empty-text {
  font-size: var(--font-size-x-small);
  font-weight: var(--font-weight-medium);
  color: var(--gray-darken-3);
}
</style>
