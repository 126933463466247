<template>
  <VcFocusArea class="booking-item">
    <VcLayout @click="$emit('click', $event)" :align-start="small" class="spacing-gap">
      <VcLayout :wrap="small" class="spacing-gap">
        <div>
          <div class="date-text mb-1 ellipsis">{{date}}</div>
          <div class="duration-text ellipsis">{{startTime}} - {{endTime}}</div>
        </div>
        <VcLayout column justify-center :class="{'small-title': !small}">
          <div class="title-text ellipsis mb-1">{{title}}</div>
          <div class="title-text ellipsis">{{subtitle}}</div>
        </VcLayout>
      </VcLayout>

      <VcLayout v-if="client" column align-end>
        <VcAvatar :image-path="client.photo_path"
                  :name="client.full_name"
                  :color-id="client.color_id"
                  size="x-sm"
        />
      </VcLayout>
      <div v-else-if="currentAttendees" class="text-center ml-2">
        <VcIcon color="gray-darken-4" size="14px"> icon-Event_new</VcIcon>
        <div class="attendees-text mt-1">{{currentAttendees}}/{{maxAttendees}}</div>
      </div>
    </VcLayout>
  </VcFocusArea>
</template>

<script>
export default {
  name: 'BookingItem',
  props: {
    date: { type: String, required: true },
    startTime: { type: String, required: true },
    endTime: { type: String, required: true },
    title: { type: String, required: true },
    subtitle: { type: String, required: true },
    client: { type: Object },
    currentAttendees: { type: Number },
    maxAttendees: { type: Number },
    small: { type: Boolean, default: false },
  },
};
</script>

<style scoped lang="scss">
.booking-item {
  align-items: center;
  cursor: pointer;
}
.date-text {
  font-size: var(--font-size-small2);
  font-weight: var(--font-weight-medium2);
}
.duration-text {
  font-size: var(--font-size-xx-small);
  font-weight: var(--font-weight-large);
}
.title-text {
  font-size: var(--font-size-xx-small);
  font-weight: var(--font-weight-large);
  color: var(--gray-darken-4)
}
.attendees-text{
  font-size: var(--font-size-xs-small);
  font-weight: var(--font-weight-large);
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.small-title {
  max-width: 127px;
}
.spacing-gap {
  gap: var(--size-value3);
}
</style>
