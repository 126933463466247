import strftime from 'strftime';
import Vue from 'vue';
import { i18n } from '@/plugins/i18n';
import enLocale from './businessDateFormatsLocales/en.json';

let LOCALE = enLocale;
let TIME_FORMAT = null;
const SHORT_MONTH_FORMAT = '%b';
const LONG_MONTH_FORMAT = '%B';

const DATE_FORMATS_LOCALE = {
  en: () => enLocale,
  fr: () => import('./businessDateFormatsLocales/fr.json'),
  gb: () => import('./businessDateFormatsLocales/gb.json'),
  he: () => import('./businessDateFormatsLocales/he.json'),
};

function thisYear(date) {
  if (typeof date !== 'object') return false;
  const today = new Date();
  return date.getFullYear() === today.getFullYear();
}

function isToday(date) {
  const today = new Date();
  return date.getDate() === today.getDate()
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear();
}

function is24hours() {
  return TIME_FORMAT === '24h';
}

function getDateFormats() {
  return LOCALE.date.formats;
}

function getTimeFormats() {
  return LOCALE.time.formats;
}

function getFormatter() {
  const dateFormats = LOCALE.date;
  const timeFormats = LOCALE.time;
  const locale = {
    ...timeFormats,
    days: dateFormats.day_names,
    shortDays: dateFormats.abbr_day_names,
    months: dateFormats.month_names.filter((mo) => !!mo),
    shortMonths: dateFormats.abbr_month_names.filter((mo) => !!mo),
  };

  return strftime.localize(locale);
}

const init = (locale, timeFormat) => {
  LOCALE = locale;
  TIME_FORMAT = timeFormat;
};

const setLocale = async (locale, timeFormat) => {
  const dateFormats = DATE_FORMATS_LOCALE[locale] ? await DATE_FORMATS_LOCALE[locale]() : LOCALE || await DATE_FORMATS_LOCALE.en();
  init(dateFormats, timeFormat || TIME_FORMAT || '24h');
};

/**
* @name  timeTodayOrDate
* @param {String} dateIso - date as iso format
* @param {Object} options - short: true/false
*
* @return
* Today         - 10:00 pm
* This year     - Jul 8
* Not this year - Jul 8, 2012
*
* Note: am/pm depends on the business configuration
*/
const timeTodayOrDate = (dateIso, options = {}) => {
  const dateFormats = getDateFormats();
  const timeFormats = getTimeFormats();
  const formatter = getFormatter();
  const date = new Date(dateIso);

  let format;
  if (isToday(date)) {
    format = is24hours() ? timeFormats.today_24h : timeFormats.today_12h;
  } else if (thisYear(date)) {
    format = dateFormats.month_without_year;
  } else {
    format = options.short ? timeFormats.short : dateFormats.month_with_year;
  }

  return formatter(format, date);
};

Vue.filter('timeTodayOrDate', timeTodayOrDate);

const dateRange = (startIso, endIso, isFullMonth = false) => {
  const formatter = getFormatter();
  const dateFormats = getDateFormats();
  let startFormat = dateFormats.start_range;
  let endFormat = dateFormats.end_range;
  const start = new Date(startIso);
  const end = new Date(endIso);
  if (start.getMonth() !== end.getMonth()) {
    startFormat = dateFormats.start_range_with_month;
    endFormat = dateFormats.long_end_range;
  }
  if (start.getFullYear() !== end.getFullYear()) {
    startFormat = dateFormats.start_range_with_year;
    endFormat = dateFormats.long_end_range;
  }
  if (isFullMonth) {
    startFormat = startFormat.replace(SHORT_MONTH_FORMAT, LONG_MONTH_FORMAT);
    endFormat = endFormat.replace(SHORT_MONTH_FORMAT, LONG_MONTH_FORMAT);
  }
  return `${formatter(startFormat, start)} - ${formatter(endFormat, end)}`;
};

/**
 * This is the main helper to show a date & time span of an event
 * Will show the full date (omits current year) and the end time (same day) or end date
 * @param startIso
 * @param endIso
 * @returns {string}
 *  # This year, same day       - Tue, July 8, 10:00am - 3:00pm
 *  # This year, different day  - Tue, July 8, 10:00am - Wed, July 9, 3:00pm
 *  # Not this year, same day   - Tue, July 8, 2014, 10:00am - 3:00pm
 *  # Different years           - Wed, December 31, 2014, 11:00pm - Thu, January 1, 2015, 8:00am
 */
const eventTime = (startIso, endIso) => {
  const formatter = getFormatter();
  const timeFormats = getTimeFormats();
  let startFormat = is24hours() ? timeFormats.day_without_year_short_month_24h : timeFormats.day_without_year_short_month_12h;
  let endFormat = is24hours() ? timeFormats.day_without_year_short_month_24h : timeFormats.day_without_year_short_month_12h;
  const start = new Date(startIso);
  const end = new Date(endIso);

  if (start.getFullYear() !== end.getFullYear() || !thisYear(start)) {
    startFormat = is24hours() ? timeFormats.day_with_year_short_month_24h : timeFormats.day_with_year_short_month_12h;
  }

  if (start.getDate() === end.getDate()) {
    endFormat = is24hours() ? timeFormats.time_24h : timeFormats.time_12h;
  } else if (start.getFullYear() !== end.getFullYear()) {
    endFormat = is24hours() ? timeFormats.day_with_year_short_month_24h : timeFormats.day_with_year_short_month_12h;
  }

  return `${formatter(startFormat, start)} - ${formatter(endFormat, end)}`;
};

Vue.filter('eventTime', eventTime);

/**
* @name  dateNoCurrentYear
* @param {String} dateIso - date as iso format
*
* @return
* example input: 2020-11-09T18:11:41.000+02:00
* This year     - Jul 8
* Not this year - Jul 8, 2012
*/
const dateNoCurrentYear = (dateIso, isFullMonth = false, adjustTimezone = false) => {
  const formatter = getFormatter();
  const dateFormats = getDateFormats();
  const timeZoneAdjustment = adjustTimezone ? `${dateIso.replace(/T.*$/, '')}T00:00:00` : dateIso;
  const date = new Date(timeZoneAdjustment);
  let format = thisYear(date) ? dateFormats.month_without_year : dateFormats.month_with_year;
  if (isFullMonth) {
    format = format.replace(SHORT_MONTH_FORMAT, LONG_MONTH_FORMAT);
  }
  return formatter(format, date);
};

Vue.filter('dateNoCurrentYear', dateNoCurrentYear);

/**
* @name  timeAt
* @param {String} dateIso - date as iso format
*
* @return
* example input: 2020-11-09T18:11:41.000+02:00
* output: Mon, November 09 at 6:11pm
* Note: am/pm depends on the business configuration
*/
const timeAt = (dateIso) => {
  const formatter = getFormatter();
  const timeFormats = getTimeFormats();
  const date = new Date(dateIso);
  let format;
  if (thisYear(date)) {
    format = is24hours() ? timeFormats.at_without_year_24h : timeFormats.at_without_year_12h;
  } else {
    format = is24hours() ? timeFormats.at_with_year_24h : timeFormats.at_with_year_12h;
  }

  return formatter(format, date);
};

Vue.filter('timeAt', timeAt);

/**
 * @name  dateAndHour
 * @param {String} dateIso - date as iso format
 *
 * @return
 * example input: 2020-11-09T18:11:41.000+02:00
 * output this year: Nov 09, 6:11pm
 * output last year: Nov 09, 2020, 6:11pm
 * Note: am/pm depends on the business configuration
 */
const dateAndHour = (dateIso) => {
  const formatter = getFormatter();
  const timeFormats = getTimeFormats();
  const date = new Date(dateIso);
  let format;
  if (thisYear(date)) {
    format = is24hours() ? timeFormats.month_without_year_24h : timeFormats.month_without_year_12h;
  } else {
    format = is24hours() ? timeFormats.month_with_year_24h : timeFormats.month_with_year_12h;
  }
  return formatter(format, date);
};
Vue.filter('dateAndHour', dateAndHour);

/**
* @name  time
* @param {String} dateIso - date as iso format
*
* @return
* example input: 2020-11-09T18:11:41.000+02:00
* output: 6:11 pm
* Note: am/pm depends on the business configuration
*/
const time = (dateIso) => {
  const formatter = getFormatter();
  const timeFormats = getTimeFormats();
  const format = is24hours() ? timeFormats.today_24h : timeFormats.today_12h;
  const date = new Date(dateIso);
  return formatter(format, date);
};
Vue.filter('time', time);

const dayShortMonthYear = (dateIso, adjustTimezone = false) => {
  const timeZoneAdjustment = adjustTimezone ? `${dateIso.replace(/T.*$/, '')}T00:00:00` : dateIso;
  const date = new Date(timeZoneAdjustment);
  const formatter = getFormatter();
  const format = '%d %b, %Y';
  return formatter(format, date);
};
Vue.filter('dayShortMonthYear', dayShortMonthYear);

const getPluralizationSegment = (count) => {
  switch (count) {
    case 0:
      return 'zero';
    case 1:
      return 'one';
    default:
      return 'other';
  }
};

const duration = (durationInMinutes) => {
  const minutes = durationInMinutes % 60;
  const hours = Math.floor(durationInMinutes / 60) % 24;
  const days = Math.floor(Math.floor(durationInMinutes / 60) / 24);

  const durationKey = [days, hours, minutes].map(getPluralizationSegment).join('_');
  return i18n.t(`datetime.duration.short.${durationKey}`, { days, hours, minutes });
};
Vue.filter('duration', duration);

/**
 * @name  dateFormatted
 * @param {String} dateIso - date as iso format
 *
 * @return
 * example input: 2020-11-15T18:11:41.000+02:00
 * output: 2020-11-15
 */
const dateFormatted = (dateIso) => {
  const formatter = getFormatter();
  const dateFormats = getDateFormats();
  const date = new Date(dateIso);
  const format = dateFormats.default;
  return formatter(format, date);
};
Vue.filter('dateFormatted', dateFormatted);

/**
 * @name  formatWeekdayAndDay
 * @param {String} dateIso - date as iso format
 *
 * @return
 * example input: 2024-12-152T18:11:41.000+02:00
 * output: Sunday 15
 */
const formatWeekdayAndDay = (dateIso) => {
  const formatter = getFormatter();
  const date = new Date(dateIso);

  const format = '%A %d';

  return formatter(format, date);
};
Vue.filter('formatWeekdayAndDay', formatWeekdayAndDay);

export default {
  init,
  setLocale,
  timeTodayOrDate,
  dateNoCurrentYear,
  timeAt,
  time,
  dateAndHour,
  dateRange,
  eventTime,
  dayShortMonthYear,
  duration,
  dateFormatted,
  formatWeekdayAndDay,
};
