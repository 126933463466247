import productsService from '@/modules/products/service/productsService';
import importService from '@/modules/import/services/importService';

const PAGE_SIZE = 25;
const JOB_TYPES = {
  validate: 'validate',
  execute: 'execute',
};

const initialImportJobState = {
  uid: null,
  progress: null,
  fileName: null,
  status: null,
  type: null,
};
const initialState = {
  products: [],
  loading: false,
  currentPageIndex: 1,
  completed: false,
  refreshProducts: false,
  importJobState: { ...initialImportJobState },
};

const state = { ...initialState };

const mutations = {
  addProducts(state, products) {
    state.products = state.products.concat(products);
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setCompleted(state, completed) {
    state.completed = completed;
  },
  increaseCurrentPageIndex(state) {
    state.currentPageIndex += 1;
  },
  clearData(state) {
    Object.assign(state, initialState);
  },
  setRefreshProducts(state, refreshProducts) {
    state.refreshProducts = refreshProducts;
  },
  setImportJobUid(state, importJobUid) {
    state.importJobState.uid = importJobUid;
  },
  setImportJobStatus(state, importJobStatus) {
    state.importJobState.status = importJobStatus;
  },
  setImportJobType(state, importJobType) {
    state.importJobState.type = importJobType;
  },
  setImportJobProgress(state, importJobProgress) {
    state.importJobState.progress = importJobProgress;
  },
  setImportJobFileName(state, importJobFileName) {
    state.importJobState.fileName = importJobFileName;
  },
  initImportJobState(state) {
    state.importJobState = { ...initialImportJobState };
  },
};

const actions = {
  /* eslint-disable no-empty-pattern */
  async createProduct({}, product) {
    const res = await productsService.createProduct({ product });
    return res;
  },
  async loadProducts({ state, dispatch }) {
    if (state.products.length && !state.refreshProducts) return;
    await dispatch('loadMoreProducts');
  },
  async loadMoreProducts({ commit, state, rootGetters }) {
    if (state.refreshProducts) {
      commit('clearData', false);
    }
    if (state.completed || state.loading) return;
    commit('setLoading', true);

    try {
      const businessId = rootGetters['AuthStore/businessUid'];
      const products = await productsService.fetchProducts(businessId, state.currentPageIndex, PAGE_SIZE);
      commit('addProducts', products);
      commit('increaseCurrentPageIndex');
      if (products.length < PAGE_SIZE) {
        commit('setCompleted', true);
      }
    } catch (e) {
      console.log('Error loading products', e); // eslint-disable-line no-console
    }

    commit('setLoading', false);
  },
  setRefreshProducts({ commit }, refreshProducts) {
    commit('setRefreshProducts', refreshProducts);
  },
  downloadTemplateFile() {
    importService.downloadTemplate('product')
      .then(() => {
        console.log('Template file downloaded successfully');
      })
      .catch((error) => {
        console.error('Error downloading the template file:', error);
      });
  },
  async uploadFile({ commit }, fileToUpload) {
    commit('setImportJobStatus', 'uploading');
    commit('setImportJobType ', JOB_TYPES.validate);
    const responseStatus = {
      success: false,
      data: null,
      error: null,
    };
    try {
      const response = await importService.uploadFile('product', 'excel', fileToUpload.file, JOB_TYPES.validate);
      if (response.success) {
        commit('setImportJobUid', response.data.uid);
        commit('setImportJobStatus', response.data.status);
        commit('setImportJobFileName', response.data.provider_data.original_file_name);
        responseStatus.success = true;
        responseStatus.data = response.data;
      } else {
        commit('initImportJobState');
        responseStatus.error = response.data.message;
        responseStatus.success = false;
      }
    } catch (e) {
      console.log('Error uploading the file:', e);
      responseStatus.error = e;
      responseStatus.success = false;
      commit('initImportJobState');
    }
    return responseStatus;
  },
  async getImportJobStatus({ commit, state }) {
    try {
      const validationStatus = await importService.importJobStatus(state.importJobState.uid);
      if (validationStatus.success) {
        commit('setImportJobStatus', validationStatus.data.status);
        commit('setImportJobProgress', validationStatus.data.progress);
      } else {
        commit('initImportJobState');
      }
      return validationStatus.data;
    } catch (e) {
      console.log('Error getting the import job status:', e);
      commit('initImportJobState');
      return {
        status: 'error',
        error: e,
      };
    }
  },
  clearImportJobState({ commit }) {
    commit('initImportJobState');
  },
  async executImportJob({ commit }) {
    commit('setImportJobType', JOB_TYPES.execute);
    commit('setImportJobStatus', null);

    try {
      const response = await importService.executeImportJob('product', 'import_job', state.importJobState.uid, JOB_TYPES.execute);
      if (response.success) {
        commit('setImportJobStatus', response.data.status);
      } else {
        commit('setImportJobStatus', 'error');
      }
    } catch (e) {
      commit('setImportJobStatus', 'error');
      console.log('Error executing the import job:', e);
    }
  },
};

const getters = {
  products: (state) => state.products,
  getProductByUid: (state) => (uid) => state.products.find((product) => product.id === uid),
  loading: (state) => state.loading,
  completed: (state) => state.completed,
  importJobState: (state) => state.importJobState,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
