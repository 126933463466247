// const baseRoute = 'api.intandem.com/StaffWidgetsBoard?type=<type>';

// const getBoard = async () => gatewayService.get(`${baseRoute}/accounts`).then((res) => res.data.data);
const getBoard = (type) => new Promise((res) => {
  setTimeout(res(
    {
      data: {
        staffWidgetsBoards: [
          {
            uid: 'e64faa3c-dab6-4ba3-bc41-f1216668e616',
            board_layout_code_name: 'MainAndSideBar2Columns',
            type,
            sections: [
              {
                uid: 'e64faa3c-dab6-4ba3-bc41-f1216668e617',
                code_name: 'main',
                widgets: [
                  {
                    uid: '62dde7ab-f10f-444d-88d5-5358d7917ba1',
                    app_code_name: 'books_example',
                    display_name: {
                      en: 'books books books',
                      fr: 'books in french',
                    },
                    permissions: [
                      'payments_edit',
                      'payments_read',
                    ],
                    module: 'Books',
                    dimensions: {
                      width: 4,
                      height: 4,
                      max_width: 6,
                      min_width: 1,
                      max_height: 2,
                      min_height: 1,
                    },
                    component_data: {
                      name: 'BooksExample',
                      config: {
                        url: 'www.partnerdomain.com/calendar',
                        title: 'calendar',
                      },
                    },
                    created_at: '2024-12-15T08:32:47.252Z',
                    updated_at: '2024-12-15T08:32:47.252Z',
                  },
                  {
                    uid: '268f9342-7cf8-4e79-9e7e-e79477ad2301',
                    app_code_name: 'tips A',
                    display_name: {
                      en: 'Payments',
                      fr: 'Paiements',
                    },
                    permissions: [
                      'payments_edit',
                      'payments_read',
                    ],
                    module: 'Payment',
                    dimensions: {
                      width: 2,
                      height: 1,
                      max_width: 4,
                      min_width: 1,
                      max_height: 2,
                      min_height: 1,
                    },
                    component_data: {
                      name: 'iFrame',
                      config: {
                        url: 'https://image.shutterstock.com/image-photo/circular-architecture-hong-kong-260nw-252969499.jpg',
                        title: 'calendar',
                      },
                    },
                    created_at: '2024-12-15T08:32:13.819Z',
                    updated_at: '2024-12-15T08:32:13.819Z',
                  },
                  {
                    uid: '268f9342-7cf8-4e79-9e7e-e79477ad2302',
                    app_code_name: 'tips B',
                    display_name: {
                      en: 'Payments',
                      fr: 'Paiements',
                    },
                    permissions: [
                      'payments_edit',
                      'payments_read',
                    ],
                    module: 'Payment',
                    dimensions: {
                      width: 2,
                      height: 1,
                      max_width: 4,
                      min_width: 1,
                      max_height: 2,
                      min_height: 1,
                    },
                    component_data: {
                      name: 'iFrame',
                      config: {
                        url: 'https://image.shutterstock.com/image-photo/circular-architecture-hong-kong-260nw-252969499.jpg',
                        title: 'calendar',
                      },
                    },
                    created_at: '2024-12-15T08:32:13.819Z',
                    updated_at: '2024-12-15T08:32:13.819Z',
                  },
                  {
                    uid: '268f9342-7cf8-4e79-9e7e-e79477ad2303',
                    app_code_name: 'tips C',
                    display_name: {
                      en: 'Payments',
                      fr: 'Paiements',
                    },
                    permissions: [
                      'payments_edit',
                      'payments_read',
                    ],
                    module: 'Payment',
                    dimensions: {
                      width: 2,
                      height: 1,
                      max_width: 6,
                      min_width: 2,
                      max_height: 2,
                      min_height: 1,
                    },
                    component_data: {
                      name: 'iFrame',
                      config: {
                        url: 'https://image.shutterstock.com/image-photo/circular-architecture-hong-kong-260nw-252969499.jpg',
                        title: 'calendar',
                      },
                    },
                    created_at: '2024-12-15T08:32:13.819Z',
                    updated_at: '2024-12-15T08:32:13.819Z',
                  },
                  {
                    uid: '268f9342-7cf8-4e79-9e7e-e79477ad2304',
                    app_code_name: 'tips D',
                    display_name: {
                      en: 'Payments',
                      fr: 'Paiements',
                    },
                    permissions: [
                      'payments_edit',
                      'payments_read',
                    ],
                    module: 'Payment',
                    dimensions: {
                      width: 4,
                      height: 1,
                      max_width: 6,
                      min_width: 2,
                      max_height: 2,
                      min_height: 1,
                    },
                    component_data: {
                      name: 'iFrame',
                      config: {
                        url: 'https://image.shutterstock.com/image-photo/circular-architecture-hong-kong-260nw-252969499.jpg',
                        title: 'calendar',
                      },
                    },
                    created_at: '2024-12-15T08:32:13.819Z',
                    updated_at: '2024-12-15T08:32:13.819Z',
                  },
                  {
                    uid: '268f9342-7cf8-4e79-9e7e-e79477ad2305',
                    app_code_name: 'tips E',
                    display_name: {
                      en: 'Payments',
                      fr: 'Paiements',
                    },
                    permissions: [
                      'payments_edit',
                      'payments_read',
                    ],
                    module: 'Payment',
                    dimensions: {
                      width: 6,
                      height: 1,
                      max_width: 6,
                      min_width: 2,
                      max_height: 2,
                      min_height: 1,
                    },
                    component_data: {
                      name: 'iFrame',
                      config: {
                        url: 'https://image.shutterstock.com/image-photo/circular-architecture-hong-kong-260nw-252969499.jpg',
                        title: 'calendar',
                      },
                    },
                    created_at: '2024-12-15T08:32:13.819Z',
                    updated_at: '2024-12-15T08:32:13.819Z',
                  },
                  {
                    uid: '268f9342-7cf8-4e79-9e7e-e79477ad2307',
                    app_code_name: 'tips F',
                    display_name: {
                      en: 'Payments',
                      fr: 'Paiements',
                    },
                    permissions: [
                      'payments_edit',
                      'payments_read',
                    ],
                    module: 'Payment',
                    dimensions: {
                      width: 2,
                      height: 1,
                      max_width: 2,
                      min_width: 2,
                      max_height: 2,
                      min_height: 1,
                    },
                    component_data: {
                      name: 'iFrame',
                      config: {
                        url: 'https://image.shutterstock.com/image-photo/circular-architecture-hong-kong-260nw-252969499.jpg',
                        title: 'calendar',
                      },
                    },
                    created_at: '2024-12-15T08:32:13.819Z',
                    updated_at: '2024-12-15T08:32:13.819Z',
                  },
                  {
                    uid: '268f9342-7cf8-4e79-9e7e-e79477ad2308',
                    app_code_name: 'tips G',
                    display_name: {
                      en: 'Payments',
                      fr: 'Paiements',
                    },
                    permissions: [
                      'payments_edit',
                      'payments_read',
                    ],
                    module: 'Payment',
                    dimensions: {
                      width: 4,
                      height: 1,
                      max_width: 4,
                      min_width: 4,
                      max_height: 2,
                      min_height: 1,
                    },
                    component_data: {
                      name: 'iFrame',
                      config: {
                        url: 'https://image.shutterstock.com/image-photo/circular-architecture-hong-kong-260nw-252969499.jpg',
                        title: 'calendar',
                      },
                    },
                    created_at: '2024-12-15T08:32:13.819Z',
                    updated_at: '2024-12-15T08:32:13.819Z',
                  },
                ],
                created_at: '2024-12-15T22:19:28.234Z',
                updated_at: '2024-12-15T22:19:28.000Z',
              },
              {
                uid: 'e64faa3c-dab6-4ba3-bc41-f1216668e618',
                code_name: 'side',
                widgets: [
                  {
                    uid: '7a2a1e25-40f8-452d-88da-fd020196f9a9',
                    app_code_name: 'tips H',
                    display_name: {
                      en: 'Payments',
                      fr: 'Paiements',
                    },
                    permissions: [
                      'payments_edit',
                      'payments_read',
                    ],
                    module: 'Payment',
                    dimensions: {
                      width: 4,
                      height: 2,
                      max_width: 6,
                      min_width: 2,
                      max_height: 2,
                      min_height: 1,
                    },
                    component_data: {
                      name: 'iFrame',
                      config: {
                        url: 'https://image.shutterstock.com/image-photo/spiral-staircase-modern-architecture-detail-260nw-2176956739.jpg',
                        title: 'calendar',
                      },
                    },
                    created_at: '2024-12-15T08:32:48.190Z',
                    updated_at: '2024-12-15T08:32:48.190Z',
                  },
                  {
                    uid: '67ed7e65-f7dd-4341-91ac-eaa283808443',
                    app_code_name: 'tips',
                    display_name: {
                      en: 'Payments',
                      fr: 'Paiements',
                    },
                    permissions: [
                      'payments_edit',
                      'payments_read',
                    ],
                    module: 'Payment',
                    dimensions: {
                      width: 2,
                      height: 2,
                      max_width: 3,
                      min_width: 2,
                      max_height: 2,
                      min_height: 1,
                    },
                    component_data: {
                      name: 'iFrame',
                      config: {
                        url: 'https://image.shutterstock.com/image-photo/abstract-structure-260nw-288232187.jpg',
                        title: 'calendar',
                      },
                    },
                    created_at: '2024-12-15T08:32:48.948Z',
                    updated_at: '2024-12-15T08:32:48.948Z',
                  },
                ],
                created_at: '2024-12-15T22:19:28.242Z',
                updated_at: '2024-12-15T22:19:28.000Z',
              },
            ],
            created_at: '2024-12-15T22:19:28.259Z',
            updated_at: '2024-12-15T22:19:28.259Z',
          },
        ],
      },
      success: true,
    },
  ), 2000);
});

export default {
  getBoard,
};
