const routes = [
  {
    path: '/pos',
    component: () => import(/* webpackChunkName: "PosPage" */ './views/PosPage.vue'),
    name: 'pos',
    props: (route) => ({ ...route.query, ...route.params }),
    meta: { i18n: ['point-of-sale-pov', 'datetime-pov'] },
  },
];

export default routes;
