import httpService from '@/modules/common/services/httpService';
import gatewayService from '@/modules/common/services/gatewayService';

const service = httpService.create(
  window.pov_config.VUE_APP_API_URL_GATEWAY,
  false,
  {
    responseType: 'blob',
  },
);

const basePath = '/business/import';
const uploadBasePath = '/v3/integrations/import_jobs';

const importService = {
  downloadTemplate(entity) {
    return service.get(`${basePath}/template/download?entity=${entity}`)
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.setAttribute('download', 'template.xlsx');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading the template file:', error);
        throw error;
      });
  },
  uploadFile(entity, fileType, fileData, jobType) {
    const formData = new FormData();
    formData.append('entity_type', entity);
    formData.append('provider_type', fileType);
    formData.append('provider_data[file]', fileData);
    formData.append('job_type', jobType);

    return gatewayService.post(`${uploadBasePath}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => response.data);
  },
  importJobStatus(jobUid) {
    return gatewayService.get(`${uploadBasePath}/${jobUid}`).then((response) => response.data);
  },
  executeImportJob(entity, providerType, jobUid, jobType) {
    const formData = new FormData();
    formData.append('entity_type', entity);
    formData.append('provider_type', providerType);
    formData.append('provider_data[source_import_job_uid]', jobUid);
    formData.append('job_type', jobType);

    return gatewayService.post(`${uploadBasePath}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => response.data);
  },
};

export default importService;
