import store from '@/store';
import communicationSdkService from '@/modules/common/services/communicationSdkService';

function getAuthHandler(appCodeName) {
  return communicationSdkService.getJWKS({ app_code_name: appCodeName });
}

function getUserHandler() {
  return {
    brandHost: window.location.origin,
    businessUid: store.getters['AuthStore/businessUid'],
    staffUid: store.getters['StaffStore/staffUid'],
    impersonate: store.getters.isImpersonate,
    language: store.getters['BusinessStore/locale'],
  };
}

export function startCommunicationWithSdk(appDomain, iframeId) {
  const channel = new MessageChannel();
  const frontageChannel = channel.port1;
  const externalAppChannel = channel.port2;

  function iframeMessageChannelCreator(event) {
    if (event.origin !== appDomain) {
      return;
    }

    if (event.data === 'InitWidgetEvent') {
      window.frames[iframeId].contentWindow.postMessage('InitWidgetEvent', appDomain, [externalAppChannel]);

      // Remove the event listener after the channel is created
      window.removeEventListener('message', iframeMessageChannelCreator);
    }
  }
  window.addEventListener('message', iframeMessageChannelCreator);

  return frontageChannel;
}

const actions = {
  GetAuth: getAuthHandler,
  GetUser: getUserHandler,
};

function communicationSdk(action, params) {
  if (actions[action]) {
    return actions[action](params);
  }
  console.error(`No handler was found for action "${action}"`);
  return {
    error: 'Invalid action',
  };
}

export default communicationSdk;
