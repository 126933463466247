<template>
  <VcLayout :data-qa="dataQa" fill-height align-start column>
    <VcPageHeader v-if="displayHeader" :title="appName" class="embeddedAppHeader"/>
    <div class="embedded_loader" v-if="isLoading">
      <VcSkeleton v-if="!isTimeoutSkeleton" type="card" width="100%" height="100%"/>
      <VcLoader v-else/>
    </div>
    <component :is="component"
               v-show="!isLoading"
               :appId="appId"
               :appName="appName"
               :appUrl="appUrl"
               :componentData="componentData"
               @ready="removeLoader"/>
  </VcLayout>
</template>

<script>
import BooksExample from '@/modules/Widgets/BooksExample/BooksExample.vue';
import ClientsWidget from '@/modules/widgets/clientsWidget/components/ClientsWidget.vue';
import EmbeddedAppDelegatorIframe from '@/modules/app/components/EmbeddedAppDelegatorIframe.vue';
import SchedulingWidget from '@/modules/widgets/schedulingWidget/components/SchedulingWidget.vue';

export default {
  name: 'EmbeddedAppDelegator',
  components: {
    iFrame: EmbeddedAppDelegatorIframe, BooksExample, ClientsWidget, SchedulingWidget,
  },
  props: {
    appId: {
      type: String,
      required: true,
    },
    appName: {
      type: String,
      required: true,
    },
    displayHeader: {
      type: Boolean,
      default: true,
    },
    appUrl: {
      type: String,
    },
    component: {
      type: String,
      required: true,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    componentData: {
      type: Object,
    },
    dataQa: {
      type: String,
      default: 'EmbeddedAppDelegator',
    },
  },
  data() {
    return {
      isLoading: this.showLoader,
      isTimeoutSkeleton: false,
      timeToMimicLoading: 3000,
    };
  },
  created() {
    setTimeout(() => {
      if (this.isLoading) {
        this.isTimeoutSkeleton = true;
      }
    }, this.timeToMimicLoading);
  },
  methods: {
    removeLoader() {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.embeddedAppHeader.VcPageHeader {
  margin-bottom: var(--size-value4);
}

.embedded_loader {
  width: 100%;
  height: 100%;
}
</style>
